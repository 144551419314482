import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["message"]

  connect() {
    document.addEventListener("turbo:before-cache", () => {
      this.messageTargets.forEach(message => {
        message.remove()
      });
    })
  }
}
