import BaseValidationController from "../admin/controllers/forms/base_validation_controller.js";

export default class extends BaseValidationController {
  static rules = {
    name: { presence: { allowEmpty: false } },
    email: { presence: { allowEmpty: false } },
    job_title: { presence: { allowEmpty: false } },
    place_of_work: { presence: { allowEmpty: false } },
    student_name: { presence: { allowEmpty: false } },
    relationship: { presence: { allowEmpty: false } },
    relationship_length: { presence: { allowEmpty: false } },
    intellectual_curiosity: { presence: { allowEmpty: false } },
    engagement: { presence: { allowEmpty: false } },
    focus: { presence: { allowEmpty: false } },
    writing: { presence: { allowEmpty: false } },
  };

  static validators = { validCheckbox: { attributes: ["checkbox"] } };

  validCheckbox({ attr, value }) {
    if (value !== true) {
      this.errors.add(attr, "You must agree before submitting");
    }
  }
}
