import { Controller } from "stimulus";
import { enter } from "el-transition";

export default class extends Controller {
  connect() {
    setTimeout(() => {
      enter(this.element);
    }, 1000)
  }
}
