import { Toggle } from "tailwindcss-stimulus-components";

export default class extends Toggle {
  static targets = ["icon", "expandIcon", "collapseIcon"];

  toggle(event) {
    super.toggle(event);
    if (this.hasIconTarget) {
      this.iconTarget.classList.toggle("rotate-180");
    }

    if (this.hasCollapseIconTarget && this.openValue) {
      this.collapseIconTarget.classList.remove("hidden");
      if (this.hasExpandIconTarget) {
        this.expandIconTarget.classList.add("hidden");
      }
    }

    if (this.hasExpandIconTarget && !this.openValue) {
      this.expandIconTarget.classList.remove("hidden");
      if (this.hasCollapseIconTarget) {
        this.collapseIconTarget.classList.add("hidden");
      }
    }
  }
}
