import { Controller } from "stimulus";
import LazyLoad from "vanilla-lazyload";

export default class extends Controller {
  connect() {
    this.lazyLoad = new LazyLoad({
      class_loading: "img-loading",
    });
  }
}
