import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["btn"]
  connect() {
    this.expanded = false;
    if (this.element.scrollHeight > this.element.clientHeight) {
      this.btnTarget.classList.remove("hidden");
    }
  }

  toggle() {
    if (this.expanded) {
      this.element.classList.add("max-h-[200px]");
      this.btnTarget.innerHTML = "More...";
      this.expanded = false;
    } else {
      let newHeight = this.element.scrollHeight + this.btnTarget.clientHeight + 20;
      this.element.style.height = newHeight + "px";
      this.element.classList.remove("max-h-[200px]");
      this.btnTarget.innerHTML = "Less";
      this.expanded = true;
    }
    
  }
}
