import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.url = this.data.get("url") || window.location.href;
  }
  reportConversion(e) {
    if (typeof gtag !== "undefined") {
      let url = this.url;
      var callback = function () {
        if (typeof url != "undefined") {
          window.location = url;
        }
      };
      gtag("event", "conversion", {
        send_to: "AW-983791653/m2vzCI_YuIcBEKXwjdUD",
        event_callback: callback,
      });
    }
    return false;
  }
}
