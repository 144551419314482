import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    if (this.urlParams()["mercury_frame"] === "true") {
      // on the edit page, set things to be editable
      this.setItemsOpen();
      this.preventPointerEvents();
    } else {
      this.allowPointerEvents();
    }
  }

  urlParams() {
    var vars = { mercury_frame: false };
    window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  }

  get checkboxes() {
    return new Array(...this.element.querySelectorAll("input[type=checkbox]"));
  }

  get selectedCheckboxes() {
    return this.checkboxes.filter((c) => c.checked);
  }

  get items() {
    return new Array(...this.element.querySelectorAll(".accordion__list-item"));
  }

  setItemsOpen() {
    this.checkboxes.forEach(function (checkbox) {
      checkbox.checked = false;
    });
  }

  preventPointerEvents() {
    this.items.forEach(function (item) {
      item.style = "pointer-events: none";
    });
  }

  allowPointerEvents() {
    this.items.forEach(function (item) {
      item.removeAttribute("style");
    });
  }
}
