import { Controller } from "stimulus";
import flatpickr from "flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

export default class extends Controller {
  static targets = ["field"];
  static values = {
    dateFormat: { type: String, default: "m/Y" },
    altFormat: { type: String, default: "m/Y" },
    altInput: { type: Boolean, default: false },
  };

  connect() {
    this.flatpickr = flatpickr(this.fieldTarget, {
      dateFormat: this.dateFormatValue,
      altFormat: this.altFormatValue,
      altInput: this.altInputValue,
      plugins: [
        new monthSelectPlugin({
          dateFormat: this.dateFormatValue,
          altFormat: this.altFormatValue,
        }),
      ],
    });
  }

  disconnect() {
    this.flatpickr.destroy();
  }
}
