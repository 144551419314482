import BaseValidationController from "../admin/controllers/forms/base_validation_controller.js";

export default class extends BaseValidationController {
  static targets = ["checkbox", "btn"];

  static rules = {
    name: { presence: { allowEmpty: false } },
    email: { presence: { allowEmpty: false } },
    password: { presence: { allowEmpty: false } },
    password_confirmation: { presence: { allowEmpty: false } },
  };

  static validators = { validConsent: { attributes: ['consent' ]  },
  }

  validConsent({attr, value}) {
    // this.btnTarget.disabled = false;
    if (!this.checkboxTarget.checked) {
      this.errors.add(attr, "Consent is required");
    }
  }
}
