import { ValidationController } from "stimulus-validation";

export default class extends ValidationController {
  afterValidate({ el, attr }) {
    if (this.errors.has(attr)) {
      this.errorMessageEl(el).textContent = this.errorMessage(attr);
    } else {
      this.errorMessageEl(el).parentNode.removeChild(this.errorMessageEl(el));
    }
  }

  errorMessageEl(el) {
    let errorEl = el.closest(".form-group").querySelector(".error-text");
    if (errorEl === null) {
      errorEl = document.createElement("span");
      errorEl.classList = "error-text mt-2 text-sm text-red-600";
      el.closest(".form-group").appendChild(errorEl);
    }
    return errorEl;
  }

  errorMessage(attr) {
    return this.errors.has(attr) ? this.errors.get(attr)[0] : "";
  }
}
