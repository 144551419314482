import BaseValidationController from "../admin/controllers/forms/base_validation_controller.js";

export default class extends BaseValidationController {
  static rules = {
    name: { presence: { allowEmpty: false } },
    address: { presence: { allowEmpty: false } },
    city: { presence: { allowEmpty: false } },
    state: { presence: { allowEmpty: false } },
    email: { presence: { allowEmpty: false } },
    gender: { presence: { allowEmpty: false } },
    guardian_name: { presence: { allowEmpty: false } },
    guardian_email: { presence: { allowEmpty: false } },
    teacher_name: { presence: { allowEmpty: false } },
    teacher_email: { presence: { allowEmpty: false } },
    this_question: {
      presence: {
        allowEmpty: false,
        message: "^Your answer must be between 100 and 200 words",
      },
      length: {
        minimum: 100,
        maximum: 200,
        tokenizer: function (value) {
          return value.split(/\s+/g);
        },
        message: "^Your answer must be between 100 and 200 words",
      },
    },
    opportunity_question: {
      presence: {
        allowEmpty: false,
        message: "^Your answer must be between 100 and 200 words",
      },
      length: {
        minimum: 100,
        maximum: 200,
        tokenizer: function (value) {
          return value.split(/\s+/g);
        },
        message: "^Your answer must be between 100 and 200 words",
      },
    },
    career_question: {
      presence: {
        allowEmpty: false,
        message: "^Your answer must be between 100 and 200 words",
      },
      length: {
        minimum: 100,
        maximum: 200,
        tokenizer: function (value) {
          return value.split(/\s+/g);
        },
        message: "^Your answer must be between 100 and 200 words",
      },
    },
    challenge_question: {
      presence: {
        allowEmpty: false,
        message: "^Your answer must be between 100 and 300 words",
      },
      length: {
        minimum: 100,
        maximum: 300,
        tokenizer: function (value) {
          return value.split(/\s+/g);
        },
        message: "^Your answer must be between 100 and 300 words",
      },
    },
    favorite_book_question: {
      presence: {
        allowEmpty: false,
        message: "^Your answer must be between 100 and 200 words",
      },
      length: {
        minimum: 100,
        maximum: 200,
        tokenizer: function (value) {
          return value.split(/\s+/g);
        },
        message: "^Your answer must be between 100 and 200 words",
      },
    },
    zip: { presence: { allowEmpty: false } },
    school: { presence: { allowEmpty: false } },
    grade: { presence: { allowEmpty: false } },
    extracurricular_programs: { presence: { allowEmpty: false } },
    classes_taken: { presence: { allowEmpty: false } },
    race: { presence: { allowEmpty: false } },
    guardian_education: { presence: { allowEmpty: false } },
    time_zone: { presence: { allowEmpty: false } },
  };

  static validators = { validCheckbox: { attributes: ["checkbox"] } };

  validCheckbox({ attr, value }) {
    if (value !== true) {
      this.errors.add(attr, "You must agree before submitting");
    }
  }
}
