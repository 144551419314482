window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());

document.addEventListener("turbo:load", function (event) {
  // gtag("config", "UA-43143595-1", {
  //   page_location: event.data.url,
  //   page_path: event.srcElement.location.pathname,
  //   page_title: event.srcElement.title,
  // });
  gtag('config', 'G-5SC4THSBLQ');
});

export default gtag;
