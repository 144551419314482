import { Controller } from "stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["dropdown", "btn"];

  connect() {
    this.isOpen = this.dropdownTarget.classList.contains("opaque");
  }

  toggle(e) {
    if (this.isOpen) {
      this.close(e);
    } else {
      this.open(e);
    }
  }

  open(e) {
    enter(this.dropdownTarget).then(() => {
      this.btnTarget.setAttribute("aria-expanded", "true");
      this.isOpen = true;
    });
  }

  close(e) {
    leave(this.dropdownTarget).then(() => {
      this.btnTarget.setAttribute("aria-expanded", "false");
      this.isOpen = false;
    });
  }

  closeOnOutsideClick(e) {
    if (
      e.target == this.element ||
      this.element.contains(e.target)
    ) {
      return;
    }
    if (this.isOpen) {
      this.close(e);
    }
  }

  closeViaKeyboard(e) {
    if (e.key === "Escape") {
      this.close(e);
    }
  }
}
