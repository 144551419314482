import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["video", "background", "closeButton", "overlay"];

  connect() {
    this.isFullscreen = false;
    this.screenWidth = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    this.videoLaunched = false;
  }

  toggleVideo() {
    if (this.screenWidth > 768) {
      if (this.backgroundTarget.classList.contains("video-expanded")) {
        this.backgroundTarget.style.position = "relative";
        this.backgroundTarget.classList.remove(("video-expanded"));
        this.isFullscreen = false;
      } else {
        this.backgroundTarget.style.position = "fixed";
        this.backgroundTarget.classList.add("video-expanded");
        this.videoTarget.play();
        this.overlayTarget.remove();
        this.isFullscreen = true;
      }
      this.closeButtonTarget.classList.toggle("hidden");
    }
  }

  toggleWithKeyboard(e) {
    if (
      e.key === "Escape" &&
      $(this.backgroundTarget).hasClass("video-expanded")
    ) {
      this.toggleVideo();
    }
  }

  toggleWithClick(e) {
    e.stopPropagation();
    this.toggleVideo();
  }
}
