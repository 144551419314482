import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["country"];
  static values = {
    countries: { type: Array, default: []},
    fill: { type: String, default: "#f58238"}
  }

  connect() {
    this.countryTargets.forEach(country => {
      let value = country.dataset.authorMapCountryIso;
      if (this.countriesValue.includes(value)) {
        country.setAttribute("fill", this.fillValue);
      }
    });
  }
}
