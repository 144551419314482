import BaseValidationController from "../admin/controllers/forms/base_validation_controller.js";

export default class extends BaseValidationController {
  static rules = {
    student_email: { presence: { allowEmpty: false }, email: true },
    student_name: { presence: { allowEmpty: false } },
    mentor_email: { presence: { allowEmpty: false }, email: true },
    mentor_name: { presence: { allowEmpty: false } },
  };
}
