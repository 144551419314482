import BaseValidationController from "../admin/controllers/forms/base_validation_controller.js";

export default class extends BaseValidationController {
  static rules = {
    name: { presence: { allowEmpty: false } },
    email: { presence: { allowEmpty: false }, email: true },
    grade: { presence: { allowEmpty: false } },
    school: { presence: { allowEmpty: false } },
    supervising_mentor_name: { presence: { allowEmpty: false } },
    supervising_mentor_email: { presence: { allowEmpty: false }, email: true },
    advice: { presence: { allowEmpty: false } },
    question: { presence: { allowEmpty: false } },
    research_question: { presence: { allowEmpty: false } },
    hypothesis: { presence: { allowEmpty: false } },
    data_description: { presence: { allowEmpty: false } },
    data_analysis: { presence: { allowEmpty: false } },
  };

  static validators = { validCheckbox: { attributes: ["checkbox"] } };

  validCheckbox({ attr, value }) {
    if (value !== true) {
      this.errors.add(attr, "You must agree before submitting");
    }
  }
}
